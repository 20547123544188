@mixin disable-selection
{
  // source: https://www.webpagefx.com/blog/web-design/disable-text-selection/
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

@mixin hyphen($value)
{
  hyphens: $value;
  -webkit-hyphens: $value;
  -moz-hyphens: $value;
  -ms-hyphens: $value;
}
