.posts-filter
{
  /* hide filter state */
  input
  {
    display: none;
  }

  /* toggle filter visibility */
  label[for="filters-show"]
  {
    @include disable-selection;

    display: block;
    margin-top: 10px;
    padding: 10px;

    text-align: center;
    cursor: pointer;
  }

  label[for="filters-show"]::before
  {
    content: "+";
  }

  #filters-show:checked ~ .filters-container label[for=filters-show]::before
  {
    content: "-";
  }

  #filters-show:checked ~ .filters-container .filters
  {
    height: auto;
    opacity: 1;

    transition: opacity .5s;
  }

  /* filters */
  .filters
  {
    display: grid;
    grid-template: "domain" "categories" "tags" "footer";
    margin-bottom: 25px;
    height: 0;
    overflow: hidden;

    opacity: 0;


    .filter
    {
      margin: 10px;
      margin-bottom: 10px;

      .title
      {
        color: #ccc;
        margin-bottom: 15px;
        padding: 5px;

        border-bottom: 1px solid #eee;
      }

      .attributes
      {
        label
        {
          @include disable-selection;

          display: inline-block;
          padding: 10px;
          margin: 3px;

          border-radius: 3px;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          background: #e9e9e9 url('/assets/zebra-pattern.jpg') fixed;
          color: #333;
          cursor: pointer;
        }

        label:hover
        {
          background: #f5f5f5;
          border-right-color: #aaa;
          border-bottom-color: #aaa;
          color: #666;
        }
      }
    }

    .filter.domains
    {
      grid-area: domain;
    }

    .filter.categories
    {
      grid-area: categories;
    }

    .filter.tags
    {
      grid-area: tags;
    }

    .filters-footer
    {
      grid-area: footer;
      margin-top: 20px;
    }
  }

  /* results */
  .results
  {
    .message
    {
      display: none;
      padding: 25px;
      margin: 10px 0px;

      border: 1px solid grey;
      background: url('/assets/zebra-pattern.jpg');
    }

    // results are hidden unless the filter show them
    .result
    {
      display: none;
    }

    .results-footer
    {
      display: block;
      margin: 25px 0;
      padding: 15px;

      text-decoration: none;
      background-color: #838383;
      color: #555;
      text-align: center;
      background: #e9e9e9 url('/assets/zebra-pattern.jpg') fixed;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
}


@media screen and (min-width: $mobile-max-width)
{
  .posts-filter
  {
    label[for=filters-show]
    {
      display: none;
    }

    .filters
    {
      height: auto;
      grid-template: "domain domain" "categories tags" "footer footer";

      opacity: 1;
    }
  }
}
