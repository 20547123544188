article .info-container .content
{
  img,
  .flickr-embed-frame
  {
    box-sizing: border-box;
    width: 100%;

    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
    border: 5px solid var(--background-color);
  }
}
