body
{
  --domain-color1: var(--domain-default-color1);
  --domain-color2: var(--domain-default-color2);
  --domain-color3: var(--domain-default-color3);
}

body.domain-mind
{
  --domain-color1: var(--domain-mind-color1);
  --domain-color2: var(--domain-mind-color2);
  --domain-color3: var(--domain-mind-color3);
}

body.domain-body
{
  --domain-color1: var(--domain-body-color1);
  --domain-color2: var(--domain-body-color2);
  --domain-color3: var(--domain-body-color3);
}

body.domain-photos
{
  --domain-color1: var(--domain-photos-color1);
  --domain-color2: var(--domain-photos-color2);
  --domain-color3: var(--domain-photos-color3);
}

body
{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: var(--min-width);

  background-color: var(--background-color);
}

.page-header-wrapper,
.page-content-wrapper
{
  margin: 0 var(--content-gutter-width);
}

.page-content-wrapper
{
  flex: 1 0 auto;
}

.page-header-wrapper,
.page-footer
{
  flex-shrink: 0;
}

main
{
  max-width: 700px;
  height: 100%;
  margin: auto;

  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;


  a
  {
    color: var(--domain-color1);
  }

  a:hover
  {
    background-color: var(--domain-color3);
  }
}

.shadow-divider-bottom::after
{
  display: block;
  height: 30px;
  margin-bottom: 5px;
  content: '';

  border-bottom: 1px solid var(--divider-color);
  box-shadow: 0 5px 5px -5px var(--divider-color);
  margin-top: -30px;
}


@media screen and (min-width: $mobile-max-width)
{
  main
  {
    padding: 15px;
  }
}
