$mobile-max-width: 520px;

:root
{
  --min-width: 350px;
  --content-gutter-width: 5%;

  --divider-color: #e9e9e9;
  --background-color: #fdfdfd;

  --mobile-max-width: 520px;

  // domains
  --domain-default-color1: #333;
  --domain-default-color2: #666;
  --domain-default-color3: #ddd;

  --domain-mind-color1: #3a80fb;
  --domain-mind-color2: #163160;
  --domain-mind-color3: #e1eeff;

  --domain-body-color1: #d75623; /*#ec6a18*//*#db5500;*/
  --domain-body-color2: #4a1e0d;
  --domain-body-color3: #fce9e3; /*#ffece6*/

  --domain-photos-color1: #262626; /*#2bb927*/ /*#36ba15*/
  --domain-photos-color3: #868686; /*#e2fce0*/ /*#e2fce0e0fbde*/
}

#header
{
  --text-color: #333;
}
