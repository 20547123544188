/*
 * header
 */

#header
{
  font-family: arial;
  font-size: 12pt;
  color: var(--text-color);
  background-color: var(--background-color);

  a
  {
    color: var(--text-color);
    text-decoration: none;
  }

  a:hover
  {
    text-decoration: underline;
  }

  .content
  {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .site-title
    {
      display: inline-flex;
      align-items: center;
      padding: 10px;

      filter: grayscale(100%);

      transition: all .5s ease-out;

      .image
      {
        display: none;
        width: 50px;
        height: 50px;

        background-image: url('author.jpg');
        background-position: 50% 50%;
        background-size: cover;
      }

      .name
      {
        font-size: 15pt;
      }
    }

    .site-title:hover
    {
      background-color: var(--domain-color1);
      color: white;
      text-shadow: 1px 1px 1px black;
      filter: none;

      transition: all .5s ease-in;

      .image
      {
        box-shadow: 1px 1px 1px black;
      }
    }

    .primary-links
    {
      .link
      {
        padding: 4px;
        border-radius: 3px;
      }
    }
  }
}

body.domain-mind #header .content .primary-links .link.mind,
#header .content .primary-links .link.mind:hover
{
  color:var(--domain-mind-color1);
}

body.domain-body #header .content .primary-links .link.body,
#header .content .primary-links .link.body:hover
{
  color:var(--domain-body-color1);
}

body.domain-photos #header .content .primary-links .link.photos,
#header .content .primary-links .link.photos:hover
{
  color:var(--domain-photos-color1);
}


@media screen and (min-width: $mobile-max-width)
{
  #header .content
  {
    .site-title
    {
      padding: 5px;

      .image
      {
        display: inline-block;
      }

      .name
      {
        margin: 0 20px;
      }
    }

    .primary-links .link
    {
      padding: 15px;
    }
  }
}
