article .content code
{
  padding: 1px 3px;

  border: 1px solid #ccc;
  border-bottom: 1px solid #aaa;
  background-color: white;
  box-shadow: 1px 1px 1px lightgrey;
  color: #aaa;
  font-style: italic;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo,monospace;
  font-size: .9em;
}

article .content figure.highlight
{
    margin: 0;
    overflow-x: auto;

    border-radius: 6px;
    border: 1px solid #ddd;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 0px 4px lightgrey;

    font-size: 10pt;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo,monospace;
    line-height: 1.5em;

    .lineno
    {
        padding: 12px;

        border-radius: 4px;
        color: #404855;
        background-image: url('/assets/zebra-pattern-vertical.jpg');
        background-attachment: fixed;
    }

    .code
    {
        white-space: nowrap;
        padding: 10px;
        word-wrap: normal;
        color: #102337;
    }

    // resets
    pre, code
    {
        font-family: inherit;
    }

    pre
    {
        margin: 0;
    }

    code
    {
        padding: 0;
        border: none;

        font-style: inherit;
    }
    
    table td
    {
        padding: 2px;
    }
}

article .content pre.highlight
{
    margin: 0;
    padding: 10px;
    overflow-x: auto;

    border-radius: 6px;
    border: 1px solid #ddd;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 0px 4px lightgrey;

    font-size: 12pt;
    line-height: 1.5em;
    
    code
    {
        border: none;
        box-shadow: none;
        font-style: inherit;
    }
}