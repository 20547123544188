// common
@keyframes highlight-quick
{
  25%
  {
    background-color: #fff3b0;
  }
}

article *:target
{
  animation: 5s highlight-quick;
}

// header
@keyframes scalein
{
  from
  {
    transform: scale(1.05);
    background-color: var(--domain-color3);
  }

  to
  {
    transform: scale(1);
    background-color: none;
  }
}

article .article-header *::selection,
article .content *::selection
{
  background-color: var(--domain-color3);
}

article .article-header *::-moz-selection,
article .content *::-moz-selection
{
  background-color: var(--domain-color3);
}

article .article-header
{
  margin: 30px 0;
  padding: 10px 25px;

  color: var(--domain-color1);
  text-shadow: 1px 1px 1px rgba(0,0,0,.1);
  border: 1px solid var(--domain-color1);
  box-shadow: 1px 1px 5px lightgrey;
  //background-color: var(--domain-color3);

  animation: .75s scalein;

  .title
   {
     margin-bottom: .25em;

     font-size: 25pt;
     font-weight: bold;
   }

   .subtitle
   {
     font-size: 15pt;
   }

  .info-container
  {
    margin: 20px;


    .info
    {
      display: inline-block;
      margin: 5px;
    }

    .info::before
    {
      padding: 0 4px;
      border-radius: 2px;
      color: white;
      background-color: var(--domain-color1);
      margin-right: 10px;
    }

    .info.reading-time::before
    {
      content: 'Time:'
    }

    .info.updated::before
    {
      content: 'Updated:'
    }

    .info.created::before
    {
      content: 'Posted:'
    }

    .info.tags::before
    {
      content: 'Tags:'
    }
  }
}

// content
article .content
{
  margin: 20px 10px 40px 10px;

  font-size: 14pt;
  font-family: "Lato","proxima-nova","Helvetica Neue",Arial,sans-serif;
  line-height: 1.6em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  @include hyphen(auto);
  color: var(--domain-color2);
  // color: #333;


  img, .flickr-embed-frame
  {
    box-sizing: border-box;
    width: 100%;

    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
    border: 5px solid var(--background-color);
  }

  h1::before,
  h2::before,
  h3::before,
  h4::before,
  h5::before
  {
    color: var(--domain-color3);
    opacity: .75;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h5
  {
    display: table;
    margin-bottom: 0.5em;
    margin-top: 2em;
    padding: 5px;

    // border-bottom: 1px solid var(--domain-color1);
    // color: var(--domain-color1);
    background: var(--domain-color1);
    color: white;
    line-height: 1em;
    border-bottom: 1px solid var(--domain-color2);
    border-right: 1px solid var(--domain-color2);

    transition: opacity ease-out .5s;
  }

  h1
  {
    font-size: 110%;
  }

  h1::before
  {
    content: "# "
  }

  h2
  {
    font-size: 105%;
  }

  h2::before
  {
    content: "## "
  }

  h3
  {
    font-size: 100%;
  }

  h3::before
  {
    content: "### ";
  }

  h4
  {
    font-size: 95%;
  }

  h4::before
  {
    content: "#### ";
  }

  h5
  {
    font-size: 90%;
  }

  h5::before
  {
    content: "##### ";
  }

  p
  {
    margin: .75em 0;
  }

  ul li, ol li
  {
    margin-left: 1.75em;
  }

  ol li::marker
  {
    font-weight: bold;
  }

  strong
  {
    font-weight: bold;
  }

  em
  {
    font-style: italic;
  }

  blockquote
  {
    margin: 0;
    padding-left: 15px;

    border-left: 5px solid var(--domain-color3);
  }
}

// footer
article .article-footer
{
  section
  {
    margin-top: 30px;
  }

  .tags
  {
    margin: 10px 0;


    .tag
    {
      display: inline-block;
      margin: 3px;
      padding: 10px;
      border-radius: 3px;

      color: var(--domain-color1);
      background: var(--domain-color3);
      border-bottom: 1px solid var(--domain-color1);
      border-right: 1px solid var(--domain-color1);
      text-decoration: none;
    }

    .tag:hover
    {
      text-decoration: underline;
      background-color: var(--domain-color1);
      border-bottom-color: var(--domain-color2);
      border-right-color: var(--domain-color2);
      color: white;
    }
  }

  .action-bar
  {
    display: flex;
    justify-content: space-between;

    margin: 10px 2px;
    padding: 10px;

    border: 1px solid var(--domain-color1);
    background-color: white;
    box-shadow: 1px 1px 5px lightgrey;


    a
    {
      text-decoration: none;
      color: var(--domain-color1);
    }

    a:hover
    {
      text-decoration: underline;
      background-color: transparent;
    }
  }

  .article-comments
  {
    .email
    {
      text-align: center;
      padding: 30px;
    }
  }

  // .article-comments
  // {
  //   filter: grayscale(1);
  //   transition: 3s;
  // }
  //
  // .article-comments:hover
  // {
  //   filter: grayscale(0);
  //   transition: 1s;
  // }
}


@media screen and (min-width: $mobile-max-width)
{

  article .article-header
  {
    padding: 15px 35px;


    .title
    {
      font-size: 38pt;
    }

    .subtitle
    {
      font-size: 18pt;
    }

  }

  article .content
  {
    font-size: 15pt;
    margin-left: 25px;
    margin-right: 25px;
  }
}
