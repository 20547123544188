.section-title
{
    display: inline-block;
    padding: 10px;

    color: #555;
    background-color: #ddd;
    font-style: italic;
    font-size: 100%;
}

.section-content
{
  margin-top: 15px;
}

.article-stubs-container
{
  .footer
  {
    padding: 15px;
    display: block;

    text-align: center;

    a
    {
      color: #555;
      text-decoration: none;
    }

    a:hover
    {
      text-decoration: underline;
      background: none;
    }
  }
}


main > .home
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90%;
  margin: 40px 0;
}