.gist .gist-file
{
  box-shadow: 0px 0px 4px lightgrey;


  a:hover
  {
    background-color: transparent;
  }

  .gist-meta
  {
      background-image: url('/assets/zebra-pattern.jpg');
      background-attachment: fixed;
  }
}
