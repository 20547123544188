@keyframes hello-header
{
    from
    {
        transform: scale(1.04);
    }

    to
    {
        transform: scale(1);
    }
}

@keyframes hello-about
{
    from
    {
        opacity: 0;
    }
    
    to
    {
        opacity: 1;
    }
}

@keyframes hello-sections
{
    from, 10%
    {
        opacity: 0;
    }
    
    to
    {
        opacity: 1;
    }
}

main > .hello
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 90%;
    margin-top: 60px;

    header
    {
        animation: 3s hello-header;

        .splash
        {
            width: 100%;
            padding: 5%;
        }
    }

    section
    {
        margin-top: 60px;
    }

    section .section-content
    {
        animation: 5s hello-sections;
    }

    section.about
    {
        .section-content
        {
            display: flex;

            animation: 5s hello-about;
    
            .description
            {
                margin-left: 12px;
                max-height: 117px;
                padding: 10px;
                overflow-y: auto;

                border: 1px solid #f0f0f0;
                color: #555;
                font-size: 13pt;
                // font-style: italic;

                .highlight
                {
                    // background-color: #f4f4f4;
                    color: #222;
                    padding: 0px 4px;
                }
            }
        }
    }
}