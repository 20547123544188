h1,
h2,
h3,
h4,
h5,
h5
{
    margin: 0;
    
    font-weight: inherit;
}

p
{
    margin: 0;
}

*
{
    box-sizing: border-box;
}

ul,
ol
{
    padding: 0;
}