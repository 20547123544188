.article-stub
{
  display: block;
  margin: 15px 0;
  padding: 15px;

  border: 1px solid #666;
  text-decoration: none;
  background-color: white;
  color: #666;

  transition: border linear .1s, transform linear .1s, box-shadow linear .1s, background-color linear .15s;


  .title
  {
    font-size: 18pt;
  }

  .subtitle
  {
    margin-top: 3px;

    font-size: 13pt;
    font-style: italic;
  }

  .published
  {
    margin-top: 16px;

    font-size: 11pt;
    color: #555;
  }
}

.article-stub:hover
{
  transform: scale(1.04);
  box-shadow: 0 0 5px lightgrey;

  transition: transform linear .1s, box-shadow linear .1s, background-color linear .5s, background-color linear .5s;
}

.article-stub:visited
{
  border-color: #999;
}

.article-stub.domain-mind:hover
{
  border-color: var(--domain-mind-color1);
  color: var(--domain-mind-color1);
  background-color: var(--domain-mind-color3);
}

.article-stub.domain-body:hover
{
  border-color: var(--domain-body-color1);
  color: var(--domain-body-color1);
  background-color: var(--domain-body-color3);
}

.article-stub.domain-photos:hover
{
  border-color: var(--domain-photos-color1);
  color: var(--domain-photos-color1);
  background-color: var(--domain-photos-color3);
}
